import { tns } from "tiny-slider/src/tiny-slider";

$(document).ready(function () {
 

  let hero_slider, explore_slider, highlights_slider;
  function buildSliders() {
    if (isMobile()) {
      hero_slider = tns({
        container: ".hero--key-areas",
        autoplay: false,
        fixedWidth: 160,
        items: 3,
        controls: false,
        nav: false,
        loop: false,
        center: true,
        swipeAngle: false,
        mouseDrag: true,
        preventScrollOnTouch: "force",
      });

      // -------------------------------------~

      explore_slider = tns({
        container: ".explore--monuments",
        autoplay: false,
        fixedWidth: 218,
        items: 3,
        controls: false,
        nav: false,
        loop: false,
        center: true,
        swipeAngle: false,
        mouseDrag: true,
        preventScrollOnTouch: "force",
      });
      explore_slider
        .getInfo()
        .slideItems[explore_slider.getInfo().index].classList.add(
          "explore--item__center"
        );

      explore_slider.events.on("transitionEnd", function (info) {
        info.slideItems[info.indexCached].classList.remove(
          "explore--item__center"
        );

        info.slideItems[info.index].classList.add("explore--item__center");
      });

      highlights_slider = tns({
        container: ".highlights--wrapper",
        autoplay: false,
        fixedWidth: 218,
        controls: false,
        nav: false,
        loop: false,
        center: true,
        swipeAngle: false,
        mouseDrag: true,
        preventScrollOnTouch: "force",
      });

      //https://github.com/ganlanyuan/tiny-slider/issues/427
      highlights_slider
        .getInfo()
        .slideItems[highlights_slider.getInfo().index].classList.add(
          "highlights--item__center"
        );

      highlights_slider.events.on("transitionEnd", function (info) {
        info.slideItems[info.indexCached].classList.remove(
          "highlights--item__center"
        );

        info.slideItems[info.index].classList.add("highlights--item__center");
      });
    } else {
      highlights_slider = tns({
        container: ".highlights--wrapper",
        autoplay: false,
        fixedWidth: 304,
        nav: false,
        loop: false,
        swipeAngle: false,
        mouseDrag: true,
        gutter: 32,
        preventScrollOnTouch: "force",
        nextButton: document.getElementsByClassName(
          "carousel--button__next"
        )[0],
        prevButton: document.getElementsByClassName(
          "carousel--button__previous"
        )[0],
      });
    }
  }

  buildSliders();

  $(window).resize(function () {
    if (isMobile()) {
      if (
        hero_slider != null &&
        explore_slider != null &&
        highlights_slider != null
      ) {
        hero_slider.refresh();
      } else {
        buildSliders();
      }
    } else {
      if (highlights_slider != null) {
        const info = highlights_slider.getInfo();
        if (info.prevButton == null) {
          highlights_slider.destroy();
          highlights_slider = null;
          buildSliders();
        }
      }
      if (hero_slider != null) {
        hero_slider.destroy();
        hero_slider = null;
      }
      if (explore_slider != null) {
        explore_slider.destroy();
        explore_slider = null;
      }
    }
  });

  $(".video--badge").click(function () {
    $(".video--player__preview").hide();
    $(".video--player__player").show();

    const video_src =
      $(".video--player__player").find("iframe").attr("src") + "&autoplay=1";
    $(".video--player__player").find("iframe").attr("src", video_src);
  });
});


function isMobile() {
  return window.matchMedia("only screen and (max-width: 991px)").matches;
}
